/* global google */

import markerIcon from '@/img/marker.png';
import InfoWindow from './infoWindow';

export default class Marker {
  /**
   * create Google Maps marker
   * https://developers.google.com/maps/documentation/javascript/examples/marker-simple
   * https://developers.google.com/maps/documentation/javascript/reference/marker
   * @param options {Object}
   * @param infoWindowContent {String}
   */
  constructor(options, infoWindowContent = null) {
    // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
    const defaultOptions = {
      animation: google.maps.Animation.DROP,
      clickable: false,
      position: new google.maps.LatLng(0, 0), // default position
      map: null, // the map instance should be provided in options
      draggable: false,
    };

    this.data = {
      marker: null,
      options: { ...defaultOptions, ...options },
      infoWindowContent,
    };

    this.components = {
      infoWindow: null,
    };

    this.events = {
      onClick: null,
    };

    this.mount();
  }

  mount() {
    // Create a custom DOM element for the marker
    const markerElement = document.createElement('div');

    Object.assign(markerElement.style, {
      width: '32px',
      height: '32px',
      backgroundImage: `url(${markerIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    });

    // Create an instance of AdvancedMarkerElement
    this.data.marker = new google.maps.marker.AdvancedMarkerElement({
      content: markerElement,
      map: this.data.options.map,
      position: this.data.options.position,
    });

    // Attach infoWindow if content is passed
    if (this.data.infoWindowContent) {
      // Create infoWindow instance
      this.components.infoWindow = new InfoWindow({
        content: this.data.infoWindowContent,
      });

      // Store event
      this.events.onClick = this.onClick.bind(this);

      // Listen to the click event
      google.maps.event.addListener(this.data.marker, 'click', this.events.onClick);
    }
  }

  // marker has been clicked, open infoWindow
  onClick() {
    this.components.infoWindow.open(this.data.options.map, this.data.marker);
  }
}
